<template>
	<div class="mt-10 text-center">
		Native
	</div>
</template>

<script>
	export default{
		name: 'BranchRegist'
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,props: ['callBack', 'user']
		,data: function(){
			return {
				program: {
					title: '영업점 등록'
					,not_navigation: true
				}
			}
		}
	}
</script>